/**
 * 项目管理-项目信息
 * luxinwen
 * 2023-03-17
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_PROJECT_MGM_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.buildingNo" placeholder="请输入项目ID" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.name" placeholder="请输入项目名称" clearable />
          </FormItem>
          <FormItem>
            <Cascader v-model="formData.citys" :data="provinceList" :load-data="loadCity" placeholder="请选择项目城市" clearable></Cascader>
            <!-- <Select class="width-s" v-model="formData.cityId" placeholder="请选择项目城市" clearable>
              <Option v-for="(item, index) in cityList" :key="'city' + index" :value="item.id">{{ item.name }}</Option>
            </Select> -->
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.category" placeholder="请选择项目类型" clearable>
              <Option v-for="(item, index) in categoryList" :key="'category' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.saleType" placeholder="请选择项目分类" clearable>
              <Option v-for="(item, index) in saleTypeList" :key="'saletype' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.status" placeholder="请选择项目状态" clearable>
              <Option v-for="(item, index) in statusList" :key="'status' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content" v-grant="'OSP_PROJECT_MGM_SAVE'">
        <Button type="primary" @click="addData">新增项目</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.masterImage" class="img-preview" @click="previewImage(params.row.masterImage,'')">
          </template>
          <template slot-scope="params" slot="cityName">
            <span>{{ (allCityList.find(item => item.regionId === params.row.cityId) || {}).regionName }}</span>
          </template>
          <template slot-scope="params" slot="category">
            <span>{{ (categoryList.find(item => item.code === params.row.category) || {}).desc }}</span>
          </template>
          <template slot-scope="params" slot="saleType">
            <p v-for="(v, i) in params.row.saleType.split(',')" :key="'saleType' + i">{{ (saleTypeList.find(item => item.code === Number(v)) || {}).desc }}</p>
          </template>
          <template slot-scope="params" slot="status">
            <Tag :color="params.row.status === 2 ? 'error' : 'success'">{{ (statusList.find(item => item.code === params.row.status) || {}).desc }}</Tag>
          </template>
          <template slot-scope="params" slot="collectNum">
            <span class="link" @click="gotoCollect(params.row)">{{ params.row.collectNum }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_PROJECT_MGM_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_PROJECT_MGM_UPDATE'">编辑</Button>
            <Button @click="editPhoto(params.row)" v-grant="'OSP_PROJECT_MGM_PHOTO'">项目相册</Button>
            <Button @click="gotoType(params.row)" v-grant="'OSP_PROJECT_HOUSE'">户型维护</Button>
            <Button @click="seeCode(params.row)">查看二维码</Button>
            <Button :type="params.row.status === 2 ? 'default' : 'error'" @click="changeStatus(params.row)" v-grant="'OSP_PROJECT_MGM_CHANGE'">{{ params.row.status === 2 ? '启用' : '禁用' }}</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_PROJECT_MGM_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <edit-photo v-model="photoDisplay" :data="photoData"></edit-photo>
    <modal-preview v-model="previewImageUrl" :titleName="title"></modal-preview>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';
  import editPhoto from './photo.vue';
  import axios from 'axios';

  export default {
    components: {
      editDrawer,
      editPhoto
    },
    data() {
      return {
        title: '',
        imgUrl: '',
        formData: {
          buildingNo: '', // 项目ID
          category: null, // 房屋性质种类：0住宅，1公寓，2商业
          provinceId: null, // 省
          cityId: null, // 市
          townId: null, // 区
          name: '', // 项目名称
          saleType: null, // 销售种类 0即将开盘1热销 2推荐
          status: null, // 项目状态：1-启用，2-禁用
          citys: []
        },
        tableColumns: [
          {
            title: '项目ID',
            key: 'buildingNo'
          },
          {
            title: '项目主图',
            width: 220,
            slot: 'pic'
          },
          {
            title: '项目名称',
            key: 'name'
          },
          {
            title: '项目城市',
            slot: 'cityName'
          },
          {
            title: '项目类型',
            slot: 'category'
          },
          {
            title: '项目分类',
            slot: 'saleType'
          },
          {
            title: '项目状态',
            slot: 'status'
          },
          {
            title: '收藏数',
            slot: 'collectNum'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        photoDisplay: false,
        photoData: {},
        allCityList: [],
        provinceList: [],
        categoryList: [],
        saleTypeList: [],
        statusList: [],
        previewImageUrl: ''
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_PROJECT_MGM_VIEW, this.$grant.OSP_PROJECT_MGM_UPDATE, this.$grant.OSP_PROJECT_MGM_PHOTO, this.$grant.OSP_PROJECT_HOUSE, this.$grant.OSP_PROJECT_MGM_CHANGE, this.$grant.OSP_PROJECT_MGM_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getCityRegionAllList();
      this.getData();
      this.getProvinceRegionList();
      this.getCategory();
      this.getSaleType();
      this.getStatus();
    },
    methods: {
      /**
 * 获取用户数据
 */
      getUserInfo() {
        let val;
        if (window.sessionStorage) {
          val = window.sessionStorage.getItem(process.env.VUE_APP_SESSION);
        }
        if (!val) {
          val = window.localStorage.getItem(process.env.VUE_APP_SESSION);
        }
        if (val) {
          val = JSON.parse(val);
        } else {
          val = {};
        }
        return val;
      },
      seeCode(row) {
        axios.post(process.env.VUE_APP_END_SERVER + this.$api.project.getBuildingQrCode, {
          data: {
            id: row.id
          }
        }, {
          headers: { sessionId: this.getUserInfo().token },
          responseType: 'blob',
        }).then(res => {
          this.imgUrl = window.URL.createObjectURL(res.data);
          this.previewImage(this.imgUrl, '查看二维码');
        });

      },
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (data.citys.length > 0) {
          data.provinceId = data.citys[0];
          data.cityId = data.citys[1];
        }
        delete data.citys;
        this.$axios({
          url: this.$api.project.queryBuildingList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 获取所有城市列表
       */
      getCityRegionAllList() {
        this.$axios({
          url: this.$api.region.queryCityRegionAllList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          this.allCityList = data || [];
        });
      },
      /**
       * 查询省份列表
       */
      getProvinceRegionList() {
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName,
              children: [],
              loading: false
            });
          });
        });
      },
      /**
       * 获取城市区域列表
       */
      loadCity(item, callback) {
        this.$axios({
          url: this.$api.region.queryCityRegionList,
          data: {
            regionParentId: item.value,
            serviceType: 1
          },
          beforeSend: () => {
            item.loading = true;
          },
          complete: () => {
            item.loading = false;
          }
        }).then(data => {
          (data || []).forEach(city => {
            item.children.push({
              value: city.regionId,
              label: city.regionName
            });
          });
          callback && callback();
        });
      },
      /**
       * 获取项目类型
       */
      getCategory() {
        this.$axios({
          url: this.$api.project.queryBuildingCategory,
          data: {}
        }).then(data => {
          this.categoryList = data || [];
        });
      },
      /**
       * 获取项目分类
       */
      getSaleType() {
        this.$axios({
          url: this.$api.project.queryBuildingSaleType,
          data: {}
        }).then(data => {
          this.saleTypeList = data || [];
        });
      },
      /**
       * 获取项目状态
       */
      getStatus() {
        this.$axios({
          url: this.$api.project.queryBuildingStatus,
          data: {}
        }).then(data => {
          this.statusList = data || [];
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 项目相册
       */
      editPhoto(row) {
        this.photoData = row;
        this.photoDisplay = true;
      },
      /**
       * 户型维护
       */
      gotoType(row) {
        this.$router.push({
          name: 'projectMgmType',
          query: {
            id: row.id
          }
        });
      },
      /**
       * 收藏数
       */
      gotoCollect(row) {
        this.$router.push({
          name: 'projectMgmCollect',
          query: {
            id: row.id
          }
        });
      },
      /**
       * 禁用、启用
       */
      changeStatus(row) {
        let txt = row.status === 1 ? '禁用' : '启用';
        let status = row.status === 1 ? 2 : 1;
        this.$confirm(`确定要将 [${row.name}] 设置为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.project.changeBuildingStatus,
            data: {
              id: row.id,
              status
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: txt + '成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.name}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.project.deleteBuilding,
            data: {
              id: row.id
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 预览
       */
      previewImage(url, title) {
        this.title = title || '预览';
        this.previewImageUrl = url;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>